<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="key" prop="configKey" >
        <a-input v-model="form.configKey" placeholder="请输入key" />
      </a-form-model-item>
      <a-form-model-item label="类型分组" prop="valueType" >
        <a-select style="width: 250px" v-model="form.valueType" placeholder="请输入标签" @change="changeType">
          <a-select-option v-for="(item, index) in this.customDict.ValueType" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="配置值" prop="configValue" v-if="isShow">
        <editor v-model="form.configValue" />
      </a-form-model-item>
      <a-form-model-item label="配置值" prop="configValue" v-if="isShow1">
        <a-input v-model="form.configValue" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="名称" prop="configName" >
        <a-input v-model="form.configName" placeholder="请输入名称" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getConfig, addConfig, updateConfig } from '@/api/config/config'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import Editor from '@/components/Editor';
export default {
  name: 'CreateForm',
  props: {
  },
  components: {Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      isShow: false,
      isShow1: true,
      // 表单参数
      form: {
        id: null,

        configKey: null,

        valueType: null,

        configName: null,

        configValue: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        configKey: [
          { required: true, message: 'key不能为空', trigger: 'blur' }
        ],

        valueType: [
          { required: true, message: '类型 2:富文本,1:输入框不能为空', trigger: 'change' }
        ],

        configName: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],

        configValue: [
          { required: true, message: '配置值不能为空', trigger: 'blur' }
        ],

        isDeleted: [
          { required: true, message: '逻辑删除标记 1:删除,0:未删除不能为空', trigger: 'blur' }
        ],

        version: [
          { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        ]

      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: { ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.isShow = false
      this.isShow1 = true
      this.form = {
        id: null,

        configKey: null,

        valueType: null,

        configName: null,

        configValue: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null

      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.isShow = false
      this.isShow1 = true
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getConfig(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    changeType(value) {
      console.log("123" + value);
      this.isShow = false;
      this.isShow1 = false
      if (value == 1) {
        this.isShow = true;
        this.isShow1 = false
      } else if (value == 2) {
        this.isShow1 = true;
        this.isShow = false;
      }

    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateConfig(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addConfig(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
